<template>
    <div>
        <el-row :gutter="10">
            <el-col :span="24">
                <el-form-item :label='title'>
                    <el-upload style="float:left" action="#"  :file-list="picDataList"   ref='pictureUpload' list-type="picture-card" :auto-upload="false" :on-change='PictureChanged'>
                        <i slot="default" class="el-icon-plus"></i>
                        <div slot="file" slot-scope="{file}">
                            <img class="el-upload-list__item-thumbnail" :src="file.photo" alt="">
                              <span class="el-upload-list__item-actions">
                                  <span class="el-upload-list__item-preview" @click="handlePictureCardPreview(file)">
                                    <i class="el-icon-zoom-in"></i>
                                </span>
                                <span class="el-upload-list__item-delete" @click="handleRemove(file)">
                                    <i class="el-icon-delete"></i>
                                </span>
                            </span>
                        </div>
                    </el-upload>
                    <!-- 显示放大照片的弹框 -->
                    <el-dialog :append-to-body='true' :visible.sync="dialogVisible">
                        <img width="100%" :src="imageUrl" alt="">
                    </el-dialog>
                </el-form-item>
            </el-col>
        </el-row>
    </div>
</template>

<script>

export default {
  data: function () {
    return {
      imageUrl: '',
      dialogVisible: false,
      picDataList: []
    }
  },
  props: {
    title: {
      type: String,
      default () {
        return ''
      }
    },
    photoList: {
      type: Array,
      default () {
        return []
      }
    }
  },
  created () {
    this.picDataList = JSON.parse(JSON.stringify(this.photoList))
  },
  methods: {
    handlePictureCardPreview (file) {
      this.imageUrl = file.photo
      this.dialogVisible = true
    },
    handleRemove (file) {
      this.picDataList.splice(this.picDataList.indexOf(file), 1)
    },
    PictureChanged (file, fileList) {
      const isLt2M = file.size / 1024 / 1024 < 0.7;
        if (!isLt2M) {
          this.$message.error('上传头像图片大小不能超过 700KB!');
          return
        }
        this.getBase64(file.raw)

    },
    getBase64 (file) {
      const self = this
      const fileReader = new FileReader()
      fileReader.readAsDataURL(file)

      fileReader.onload = function (e) {
        self.picDataList.push(
          {
            uid: file.uid.toString(),
            name: file.name,
            photo: fileReader.result,
            state: 0
          }
        )
      }
    },
    ReturnPicData () {
      return this.picDataList
    }
  }
}
</script>

<style scoped>

</style>
