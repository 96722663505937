import { render, staticRenderFns } from "./UploadPicture.vue?vue&type=template&id=41f1befa&scoped=true&"
import script from "./UploadPicture.vue?vue&type=script&lang=js&"
export * from "./UploadPicture.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "41f1befa",
  null
  
)

export default component.exports