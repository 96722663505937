<template>
    <div>
        <div class="tool">
            <el-button type="primary" class="el-icon-plus" style="margin-right:5px" @click="AddClick"> 新增</el-button>
            <el-upload
                ref="upload"
                :on-change="handlePreview"
                :show-file-list="false"
                accept=".xls,.xlsx"
                :auto-upload="false"
                action="#"
                :limit="1">
                <el-button type="primary" class="el-icon-upload2" style="margin-right:5px" > 导入</el-button>
              </el-upload>
              <JsonExcel :fields="exportDataStandard" :data="exportData" type="xls" name="点位信息.xls" :fetch="createExportData" :before-finish="finishDownload" worksheet="点位信息">
                <el-button icon="el-icon-download" type="primary">导出</el-button>
              </JsonExcel>

            <el-button type="primary" class="el-icon-setting" style="margin-left:5px" @click="DefaultCity"> 默认城市</el-button>
            <el-input placeholder="点位名称或编号" style="width:200px;margin-right:20px;margin-left:20px" v-model="searchForm.searchInfo"></el-input>
            <el-button type="primary" class="el-icon-search" @click="Search"> 查询</el-button>
        </div>

        <el-table :data='MediaDataList' stripe :highlight-current-row='true' max-height='calc(100vh - 160px)' height='calc(100vh - 160px)'>
            <el-table-column align="center" prop="media_no" label="编号"></el-table-column>
            <el-table-column align="center" prop="media_name" label="名称">
              <template slot-scope="slot">
                <el-link :type="(slot.row.media_lng===undefined||slot.row.media_lng===0||slot.row.media_lng===null)?'danger':'success'" @click="openMap(slot.row)">(地图)</el-link>{{ slot.row.media_name}}
              </template>
            </el-table-column>
            <el-table-column align="center" prop="media_show_type" label="媒体类型">
              <template slot-scope="slot">
                {{getMediaType(slot.row.media_type)}}
              </template>
            </el-table-column>
            <el-table-column align="center" prop="media_province" label="省">
              <template scope="slot">
                {{CheckProvice(slot.row,0)}}
              </template>
            </el-table-column>
            <el-table-column align="center" prop="media_city" label="市">
              <template scope="slot">
                {{CheckProvice(slot.row,1)}}
              </template>
            </el-table-column>
            <el-table-column align="center" prop="media_county" label="区县">
              <template scope="slot">
                {{CheckProvice(slot.row,2)}}
              </template>
            </el-table-column>
            <el-table-column align="center" prop="media_community_addr" label="位置"></el-table-column>
            <el-table-column align="center" label="操作">
                <template slot-scope="scope">
                    <div class="link_btn">
                        <el-link type="primary" @click="EditClick(scope.row)">编辑</el-link>
                        <el-link type="danger" @click="DelClick(scope.row)">删除</el-link>
                    </div>
                </template>
            </el-table-column>
        </el-table>
        <el-pagination class="fy" layout="prev, pager, next" @current-change="current_change" :total="total" background :page-size="searchForm.pageSize"></el-pagination>

        <!--点位-->
        <el-dialog :title="title" :visible.sync="dialogVisible" :width='screenWidth<768?"90%":(screenWidth<992?"80%":"70%")' :append-to-body='true'>
            <el-form :model="mediaForm" :rules="rules" ref="mediaForm" label-width="100px" size='mini'>
              <el-collapse accordion v-model="activeNames">
                <el-collapse-item name="1">
                  <template slot="title">
                    <span style="font-size:13px;color:#409EFF"> 媒体信息</span>
                  </template>

                  <el-row :gutter="10">
                    <el-col :xs="24" :sm="12">
                      <el-form-item label="点位编号" prop="media_no">
                        <el-input v-model="mediaForm.media_no" style="width:100%"></el-input>
                      </el-form-item>
                    </el-col>
                    <el-col :xs="24" :sm="12">
                      <el-form-item label="点位名称" prop="media_name">
                        <el-input v-model="mediaForm.media_name" style="width:100%"></el-input>
                      </el-form-item>
                    </el-col>
                  </el-row>
    
                  <el-form-item label="所在城市">
                        <el-col :xs="23" :sm="15" :md="7" :lg="7">
                          <el-select v-model="mediaForm.media_province" style="width:100%;">
                            <el-option v-for="(item,key) in cityDataList.province_list" :key="key.toString()" :label="item" :value="key.toString()"></el-option>
                          </el-select>
                        </el-col>
                        <el-col :span="1"><span>&nbsp;</span></el-col>
                        <el-col :xs="23" :sm="15" :md="7" :lg="7">
                          <el-select v-model="mediaForm.media_city" style="width:100%;">
                            <el-option v-for="(item,key) in cityList" :key="key" :label="item" :value="key"></el-option>
                          </el-select>
                        </el-col>
                        <el-col :span="1"><span>&nbsp;</span></el-col>
                        <el-col :xs="24" :sm="16" :md="8" :lg="8">
                          <el-select v-model="mediaForm.media_county" style="width:100%;">
                            <el-option v-for="(item,key) in countyList" :key="key" :label="item" :value="key"></el-option>
                          </el-select>
                        </el-col>
                  </el-form-item>
                  
                  <el-row :gutter="10">
                    <el-col :xs="24" :sm="12">
                      <el-form-item label="详细地址" v-if="mediaForm.cm_media_type!==1&&mediaForm.cm_media_type!==4">
                        <el-input v-model="mediaForm.media_community_addr"></el-input>
                      </el-form-item>
                    </el-col>
                    <el-col :xs="24" :sm="12">
                      <el-form-item label="媒体类型">
                        <el-select v-model="mediaForm.media_type" style="width:100%">
                          <el-option v-for="item in $store.state.MediaTypeDataList" :key="item.mt_id" :value="item.mt_id" :label="item.mt_name"></el-option>
                          <el-option :value="0" label=" "></el-option>
                        </el-select>
                      </el-form-item>
                    </el-col>
                  </el-row>

                  <div  v-if="filterMediaType(mediaForm.media_type)">
                    <el-row :gutter="10">
                      <el-col :xs="24" :sm="12">
                        <el-form-item label="社区级别">
                          <el-input v-model="mediaForm.media_level"></el-input>
                        </el-form-item>
                      </el-col>
                      <el-col :xs="24" :sm="12">
                        <el-form-item label="社区分类">
                          <el-input v-model="mediaForm.media_community_type"></el-input>
                        </el-form-item>
                      </el-col>
                    </el-row>
                    <el-row :gutter="10">
                      <el-col :xs="24" :sm="12">
                        <el-form-item label="社区住数">
                          <el-input-number v-model="mediaForm.media_house_num" controls-position="right" :min="0" style="width:100%"></el-input-number>
                        </el-form-item>
                      </el-col>
                      <el-col :xs="24" :sm="12">
                        <el-form-item label="社区规模">
                          <el-input-number v-model="mediaForm.media_person_num" controls-position="right" :min="0" style="width:100%"></el-input-number>
                        </el-form-item>
                      </el-col>
                    </el-row>
                    <el-row :gutter="10">
                      <el-col :xs="24" :sm="12">
                        <el-form-item label="楼盘价格(万)">
                          <el-input-number v-model="mediaForm.media_house_price" controls-position="right" :min="0" style="width:100%"></el-input-number>
                        </el-form-item>
                      </el-col>
                      <el-col :xs="24" :sm="12">
                        <el-form-item label="入住时间">
                          <el-date-picker v-model="mediaForm.media_open_time" type="date" format="yyyy-MM-dd" value-format="yyyy-MM-dd" style="width: 100%;"></el-date-picker>
                        </el-form-item>
                      </el-col>
                    </el-row>
                    <el-row :gutter="10">
                      <el-col :xs="24" :sm="12">
                        <el-form-item label="楼层数">
                          <el-input-number v-model="mediaForm.media_floor_num" controls-position="right" :min="0" style="width:100%"></el-input-number>
                        </el-form-item>
                      </el-col>
                      <el-col :xs="24" :sm="12">
                        <el-form-item label="楼栋数">
                          <el-input-number v-model="mediaForm.media_building_num" controls-position="right" :min="0" style="width:100%"></el-input-number>
                        </el-form-item>
                      </el-col>
                    </el-row>
                    <el-row :gutter="10">
                      <el-col :xs="24" :sm="12">
                        <el-form-item label="电梯数">
                          <el-input-number v-model="mediaForm.media_lifts_num" controls-position="right" :min="0" style="width:100%"></el-input-number>
                        </el-form-item>
                      </el-col>
                      <el-col :xs="24" :sm="12">
                        <el-form-item label="入住率(%)">
                          <el-input-number v-model="mediaForm.media_ruzhu_rate" controls-position="right" :min="0" style="width:100%"></el-input-number>
                        </el-form-item>
                      </el-col>
                    </el-row>
                    <el-row :gutter="10">
                      <el-col :xs="24" :sm="12">
                        <el-form-item label="社区受众描述">
                          <el-input v-model="mediaForm.media_audience_detail"></el-input>
                        </el-form-item>
                      </el-col>
                      <el-col :xs="24" :sm="12">
                        <el-form-item label="社区车位数">
                          <el-input-number v-model="mediaForm.media_vehicles_number" controls-position="right" :min="0" style="width:100%"></el-input-number>
                        </el-form-item>
                      </el-col>
                    </el-row>
                    <el-form-item label="行业禁忌">
                      <el-input v-model="mediaForm.media_taboo"></el-input>
                    </el-form-item>
                  </div>
                </el-collapse-item>
                <el-collapse-item name="2">
                  <template slot="title">
                    <span style="font-size:13px;color:#409EFF"> 点位信息</span>
                  </template>

                  <el-row :gutter="10">
                    <el-col :xs="24" :sm="12" :md="8">
                      <el-form-item label="所属楼栋号">
                        <el-input v-model="mediaForm.media_sub_building" style="width:100%"></el-input>
                      </el-form-item>
                    </el-col>
                    <el-col :xs="24" :sm="12" :md="8">
                      <el-form-item label="所属单元号">
                        <el-input v-model="mediaForm.media_sub_house" style="width:100%"></el-input>
                      </el-form-item>
                    </el-col>
                    <el-col :xs="24" :sm="12" :md="8">
                      <el-form-item label="所属电梯号">
                        <el-input v-model="mediaForm.media_sub_lifts" style="width:100%"></el-input>
                      </el-form-item>
                    </el-col>
                  </el-row>

                  <el-row :gutter="10">
                    <el-col :xs="24" :sm="12">
                      <el-form-item label="展现方式">
                        <el-select v-model="mediaForm.media_show_type" style="width:100%">
                          <el-option label="灯箱广告" :value='0'></el-option>
                          <el-option label="道闸广告" :value='1'></el-option>
                          <el-option label="电梯框架" :value='2'></el-option>
                          <el-option label="广告看板" :value='3'></el-option>
                          <el-option label="屏蔽门" :value='4'></el-option>
                          <el-option label="候车亭广告" :value='5'></el-option>
                          <el-option label="车身广告" :value='6'></el-option>
                          <el-option label="其他" :value='7'></el-option>
                        </el-select>
                      </el-form-item>
                    </el-col>
                    <el-col :xs="24" :sm="12">
                      <el-form-item label="所属点位包">
                        <el-select v-model="mediaForm.media_package_id" style="width:85%">
                          <el-option :value="0" label=" "></el-option>
                          <el-option v-for="item in PackageDataList" :key="item.mp_id" :value="item.mp_id" :label="item.mp_name"></el-option>
                        </el-select>
                        <el-button type="primary" icon="el-icon-edit" style="width:8%;margin-left:5px" @click="addPackage"></el-button>
                      </el-form-item>
                    </el-col>
                  </el-row>
                 
                  <el-row :gutter="10">
                    <el-col :xs="24" :sm="12">
                      <el-form-item label="尺寸">
                        <el-input v-model="mediaForm.media_size" style="width:100%" placeholder="宽(cm)*高(cm)"></el-input>
                      </el-form-item>
                    </el-col>
                    <el-col :xs="24" :sm="12">
                      <el-form-item label="价格(元)">
                        <div class="tool">
                          <el-input-number v-model="mediaForm.media_price" controls-position="right" :precision="2" :min="0" style="width:85%"></el-input-number>
                          <el-select v-model="mediaForm.media_price_time" style="width:12%;margin-left:5px;">
                            <el-option :value="0" label="日"></el-option>
                            <el-option :value="1" label="周"></el-option>
                            <el-option :value="2" label="月"></el-option>
                            <el-option :value="3" label="年"></el-option>
                          </el-select>
                        </div>
                      </el-form-item>
                    </el-col>
                  </el-row>
                 
                  <el-row :gutter="10">
                    <el-col :xs="24" :sm="12">
                      <el-form-item label="详细地址">
                        <el-input v-model="mediaForm.media_addr" style="width:100%"></el-input>
                      </el-form-item>
                    </el-col>
                    <el-col :xs="24" :sm="12">
                      <el-form-item label="备注">
                        <el-input v-model="mediaForm.media_remark" style="width:85%"></el-input>
                      </el-form-item>
                    </el-col>
                  </el-row>
                  <uploadPic v-if="dialogVisible" :photoList="mediaForm.media_pic_list" :title="'点位照片'" ref='childRules'/>
                </el-collapse-item>
               
              </el-collapse>
                
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="dialogVisible = false">取 消</el-button>
                <el-button type="primary" @click="submit">确 定</el-button>
            </span>
        </el-dialog>

        <!--点位包-->
        <el-dialog :title="mptitle" :visible.sync="mpdialogVisible" width='30%' :append-to-body='true'>
            <el-form :model="pkForm" :rules="rules" ref="pkForm" label-width="100px" size='medium'>
                <el-form-item label="名称" prop="mp_name">
                    <el-input v-model="pkForm.mp_name"></el-input>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="mpdialogVisible = false">取 消</el-button>
                <el-button type="primary" @click="Ok">确 定</el-button>
            </span>
        </el-dialog>

        <el-dialog title="点位地图" :visible.sync="mapdialogVisible" width="80%" :append-to-body='true'>
          <baidu-map v-if="mapdialogVisible" ak='GnVkZbGd47aleyQGoGScrNDTixMiT1Ui' :center="baiduLocalSearch.lng!==0&&baiduLocalSearch.lng!==null?{lng: baiduLocalSearch.lng, lat: baiduLocalSearch.lat}:baiduLocalSearch.center" :zoom="zoom" :scroll-wheel-zoom="true" class="bm-view" :style="{height:screenHeight+'px'}" @click="MapClick" @ready='onReady'>
            <bm-control>
              <el-input placeholder="请输入地址" v-model="searchKey" class="input-with-select">
                <el-button slot="append" icon="el-icon-search" @click="MapSearch"></el-button>
              </el-input>
            </bm-control>
            <bm-local-search :keyword="localSearchKey" :autoViewport="true" :panel="false"></bm-local-search>
            <bm-marker v-if="baiduLocalSearch.lng!==0&&baiduLocalSearch.lng!==null" :position="{lng: baiduLocalSearch.lng, lat: baiduLocalSearch.lat}"></bm-marker>
          </baidu-map>
          <span slot="footer" class="dialog-footer">
                <el-button @click="mapdialogVisible = false">取 消</el-button>
                <el-button type="primary" @click="pointSet">确 定</el-button>
            </span>
        </el-dialog>

        <!--默认城市-->
        <el-dialog :title="title" :visible.sync="defaultdialogVisible" width='30%' :append-to-body='true'>
          <el-form :model="defaultCityForm" :rules="rules" ref="defaultCityForm" label-width="100px" size='medium'>
            <el-form-item label="省份">
              <el-select v-model="defaultCityForm.dc_province" style="width:100%;">
                <el-option v-for="(item,key) in cityDataList.province_list" :key="key.toString()" :label="item" :value="key.toString()"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="城市">
              <el-select v-model="defaultCityForm.dc_city" style="width:100%;">
                <el-option v-for="(item,key) in cityList" :key="key" :label="item" :value="key"></el-option>
              </el-select>
          </el-form-item>
          </el-form>
          <span slot="footer" class="dialog-footer">
              <el-button @click="defaultdialogVisible = false">取 消</el-button>
              <el-button type="primary" @click="Save">确 定</el-button>
          </span>
      </el-dialog>
    </div>
</template>

<script>
import uploadPic from '../../components/UploadPicture.vue'
import util from '../../util/util'
import { read, utils } from 'xlsx'
import BaiduMap from 'vue-baidu-map/components/map/Map.vue'
import BmMarker from 'vue-baidu-map/components/overlays/Marker.vue'
import BmControl from 'vue-baidu-map/components/controls/Control.vue'
import BmLocalSearch from 'vue-baidu-map/components/search/LocalSearch.vue'
import JsonExcel from 'vue-json-excel'

export default {
  data: function () {
    return {
      map: null,
      zoom: 12,
      activeNames:'1',

      screenWidth: 0,
      screenHeight: 0,
      cityDataList: {},
      cityList: {},
      countyList: {},
      ischange: false,

      MediaDataList: [],
      PackageDataList: [],
      searchForm: {
        searchInfo: '',
        pageSize: 20,
        pageNo: 1
      },
      total: 0,
      dialogVisible: false,
      title: '',

      mpdialogVisible: false,
      mptitle: '',
      pkForm: {
        mp_id: 0,
        mp_name: ''
      },

      mediaForm: {
        media_id: 0,
        media_package_id: null,
        media_no: '',
        media_name: '',
        media_province: 0,
        media_city: 0,
        media_county: 0,
        media_community_type: 0,
        media_community_addr: 0,
        media_level: '',
        media_type: 0,
        media_house_num: 0,
        media_person_num: 0,
        media_ruzhu_rate: 0,
        media_audience_detail: '',
        media_floor_num: 0,
        media_building_num: 0,
        media_lifts_num: 0,
        media_house_price:0,
        media_open_time:'',
        media_vehicles_number:0,
        media_taboo: '',
        media_photo: '',
        media_pic_list: [],

        
        media_show_type: 0,
        media_sub_building: '',
        media_sub_house: '',
        media_sub_lifts: '',
        media_addr: '',
        media_size: '',
        media_price: 0.00,
        media_price_time: 2,
        media_lng: 0,
        media_lat: 0,
        media_contacts: '',
        media_phone: '',
        dev_code: '',
        media_remark:''
      },
      rules: {
        media_no: [{ required: true, message: '请输入点位编号', trigger: 'blur' }],
        media_name: [{ required: true, message: '请输入点位名称', trigger: 'blur' }],
        mp_name: [{ required: true, message: '请输入点位包名称', trigger: 'blur' }],
        dc_province: [{ required: true, message: '请选择省份', trigger: 'blur' }],
        dc_city: [{ required: true, message: '请选择城市', trigger: 'blur' }],
        ms_no:[{ required: true, message: '请输入子媒体编号', trigger: 'blur' }]
      },


      // 点位地图
      mapdialogVisible: false,
      baiduLocalSearch: {
        media_id: 0,
        center: '', // 未设置坐标点时，初始化地图中心点
        lng: 0, // 设置了坐标点时
        lat: 0
      },
      searchKey:'',//搜索的关键词
      localSearchKey:'',



      // 导出
      exportDataStandard: {
        编号: 'media_no',
        名称: 'media_name',
        所属点位包: 'media_package',
        点位尺寸: 'media_size',
        价格: 'media_price',
        价格单位: {
          field: 'media_price_time',
          callback: value => {
            return value === 0 ? '日' : (value === 1 ? '周' : (value === 2 ? '月' : '年'))
          }
        },
        省: {
          field: 'media_province',
          callback: value => {
            var row = {
              media_province: value
            }
            return this.CheckProvice(row, 0)
          }
        },
        市: 'city',
        区县: 'county',
        地址: 'media_community_addr',
        媒体性质: {
          field: 'media_type',
          callback: value => {
            return  this.getMediaType(value)
          }
        },
        点位类型: {
          field: 'media_show_type',
          callback: value => {
            return value === 0 ? '灯箱广告' : (value === 1 ? '道闸广告' : (value === 2 ? '电梯框架' : value === 3 ? '广告看板' : (value === 4 ? '屏蔽门' : (value === 5 ? '候车亭广告' : (value === 6 ? '车身广告' : '其他')))))
          }
        },
        点位位置:'media_addr',
        社区级别: 'media_level',
        社区分类: 'media_community_type',
        住数: 'media_house_num',
        居住人数: 'media_person_num',
        入住率: 'media_ruzhu_rate',
        社区受众: 'media_audience_detail',
        楼层数: 'media_floor_num',
        楼栋数: 'media_building_num',
        电梯数: 'media_lifts_num',
        所属楼栋: 'media_sub_building',
        所属单元: 'media_sub_house',
        所属电梯: 'media_sub_lifts'
      },
      exportData: [],

      defaultdialogVisible:false,
      defaultCityForm:{
        dc_id:0,
        dc_province:'0',
        dc_city:'0'
      },
    }
  },
  components: {
    uploadPic,
    BaiduMap,
    BmMarker,
    BmControl,
    BmLocalSearch,
    JsonExcel
  },
  computed: {
    CheckProvice () {
      return function (row, state) {
        var name = ''
        if (row.media_province !== null && row.media_province !== undefined) {
          if (state === 0) { name = this.cityDataList.province_list[row.media_province] }
          if (state === 1) {
            name = this.cityDataList.city_list[row.media_city]
          }
          if (state === 2) {
            name = this.cityDataList.county_list[row.media_county]
          }
        }
        return name
      }
    },
    filterMediaType(){
      return function(id){
        var arr= this.$store.state.MediaTypeDataList.filter(a=>a.mt_id===id)
        let b=false
        if(arr.length>0&&arr[0].mt_type===0){
          b=true
        }
        return b
      }
    },
    getMediaType(){
      return function(id){
        var arr= this.$store.state.MediaTypeDataList.filter(a=>a.mt_id===id)
        let name='其他'
        if(arr.length>0){
          name=arr[0].mt_name
        }
        return name
      }
    },
    getPackageName(){
      return function(row){
        var arr= this.PackageDataList.filter(a=>a.mp_id===row.ms_package_id)
        let name=''
        if(arr.length>0){
          name=arr[0].mp_name
        }
        return name
      }
    }
  },
  created () {
    this.cityDataList = require('../../util/area.json')
    this.screenWidth = document.body.clientWidth
    this.screenHeight = document.body.clientHeight - 350
    util.Get('media/GetPackageDataList').then(res => {
      if (res.rpStatus === 10000) {
        this.PackageDataList = res.list
      } else { this.$message.error(res.rpMsg) }
    })
    util.Get('media/getdefaultcity').then(res => {
      this.ischange=false
      if (res.rpStatus === 10000) {
        if(res.list.length>0&&res.list[0]!==null){
          this.defaultCityForm.dc_id=res.list[0].dc_id
          this.defaultCityForm.dc_province=res.list[0].dc_province.toString()
          this.defaultCityForm.dc_city=res.list[0].dc_city.toString()
        }
      } else { this.$message.error(res.rpMsg) }
    })

    if(this.$store.state.MediaTypeDataList.length===0){
        this.$store.dispatch('getMediaTypeDataList')
    }
    this.GetDataList()
  },
  methods: {
    onReady ({ Bmap, map }) {
      this.map = map
    },
    GetDataList () {
      util.Get('media/getmediadataList', this.searchForm).then(res => {
        if (res.rpStatus === 10000) {
          this.MediaDataList = res.list
          this.total = res.total
        }
      })
    },
    current_change (page) {
      this.searchForm.pageNo = page
      this.GetDataList()
    },
    Search () {
      this.searchForm.pageNo = 1
      this.GetDataList()
    },
    AddClick () {
      this.ischange = false
      this.dialogVisible = true
      this.title = '新增资源点'
      this.mediaForm.media_id = 0
      this.mediaForm.media_no = ''
      util.Get("media/getmediano").then(res=>{
        if(res.rpStatus===10000){
          this.mediaForm.media_no=res.rpMsg
        }
      })
      this.mediaForm.media_name = ''
      this.mediaForm.media_province = this.defaultCityForm.dc_province
      this.mediaForm.media_city = this.defaultCityForm.dc_city
      this.mediaForm.media_community_type = ''
      this.mediaForm.media_county = '0'
      this.mediaForm.media_community_addr = ''
      this.mediaForm.media_level = ''
      this.mediaForm.media_type = 0
      if(this.$store.state.MediaTypeDataList.length>0){
        this.mediaForm.media_type = this.$store.state.MediaTypeDataList[0].mt_id
      }

     
      this.mediaForm.media_house_num = 0
      this.mediaForm.media_person_num = 0
      this.mediaForm.media_ruzhu_rate = 0
      this.mediaForm.media_audience_detail = ''
      this.mediaForm.media_floor_num = 0
      this.mediaForm.media_building_num = 0
      this.mediaForm.media_lifts_num = 0
      this.mediaForm.media_house_price=0
      this.mediaForm.media_open_time=''
      this.mediaForm.media_vehicles_number=0
      this.mediaForm.media_taboo = ''
      // this.mediaForm.media_photo = ''
      // this.mediaForm.media_pic_list = []

      // this.mediaForm.media_show_type = 0
      // this.mediaForm.media_belonging = 0
      // this.mediaForm.media_begin_time = new Date()
      // var date = new Date()
      // date.setMonth(date.getMonth() + 1)
      // this.mediaForm.media_end_time = new Date(date)
      // this.mediaForm.media_sub_building = ''
      // this.mediaForm.media_sub_house = ''
      // this.mediaForm.media_sub_lifts = ''
      // this.mediaForm.media_size = ''
      // this.mediaForm.media_price = 0.00
      // this.media_price_time = 2
      // this.mediaForm.media_photo = ''
      // this.mediaForm.media_lng = 0
      // this.mediaForm.media_lat = 0
      // this.mediaForm.media_photo_list = []
      // this.mediaForm.media_contacts = ''
      // this.mediaForm.media_phone = ''
    },
    EditClick (row) {
      util.Get('picture/getmediapicture', { state_type: 1, pic_name: row.media_photo }).then(res => {
        this.dialogVisible = true
        this.title = '修改点位'
        this.mediaForm = JSON.parse(JSON.stringify(row))
        this.mediaForm.media_province = row.media_province.toString()// int该为string类型
        this.mediaForm.media_city = row.media_city.toString()
        this.mediaForm.media_county = row.media_county.toString()
        if (res.rpStatus === 10000) {
          console.log(res)
          if (res.list !== null && res.list.length > 0) { this.$set(this.mediaForm, 'media_pic_list', res.list) }
        }
      })
    },
    submit () {
      this.mediaForm.media_pic_list = this.$refs.childRules.ReturnPicData()
      this.$refs.mediaForm.validate((valid) => {
        if (valid) {
          util.Post('media/edit', this.mediaForm).then(res => {
            if (res.rpStatus === 10000) {
              this.GetDataList()
              this.dialogVisible = false
            } else { this.$message.error(res.rpMsg) }
          })
        }
      })
    },
    DelClick (row) {
      this.$confirm('确定删除点位“' + row.media_name + '”？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        util.Get('media/del?id=' + row.media_id).then(res => {
          if (res.rpStatus === 10000) { this.GetDataList() } else { this.$message.error(res.rpStatus) }
        })
      }).catch(e => e)
    },

    //默认城市
    DefaultCity(){
      this.defaultdialogVisible=true
      this.title='默认城市'
      this.ischange=true
      if(this.defaultCityForm.dc_id===0){
        this.defaultCityForm.dc_province='300000'
      }
    },
    Save(){
      if(this.defaultCityForm.dc_province==='0'){
        this.$message.error('请选择省份')
        return
      }
      if(this.defaultCityForm.dc_city==='0'){
        this.$message.error('请选择城市')
        return
      }
      util.Post('media/setdefaultcity',this.defaultCityForm).then(res=>{
        if(res.rpStatus===10000){
          this.defaultCityForm.dc_id=res.list[0].dc_id
          this.defaultCityForm.dc_province=res.list[0].dc_province.toString()
          this.defaultCityForm.dc_city=res.list[0].dc_city.toString()
          this.defaultdialogVisible=false
        }
      })
    },


    // 点位包
    addPackage () {
      this.pkForm.mp_id = 0
      this.pkForm.mp_name = ''
      this.mpdialogVisible = true
      this.mptitle = '新增点位包'
    },
    Ok () {
      this.$refs.pkForm.validate((valid) => {
        if (valid) {
          util.Post('media/AddPackage', this.pkForm).then(res => {
            if (res.rpStatus === 10000) {
              this.PackageDataList = res.list
              this.mpdialogVisible = false
            } else { this.$message.error(res.rpMsg) }
          })
        }
      })
    },




    // 地图
    openMap (row) {
      this.searchKey=""
      this.localSearchKey=""
      this.mapdialogVisible = true
      this.baiduLocalSearch.media_id = row.media_id
      this.baiduLocalSearch.lng = row.media_lng
      this.baiduLocalSearch.lat = row.media_lat
      this.zoom = 18
      // 百度定位
      if (row.media_lat === null || row.media_lat === 0 || row.media_lat === undefined) {
        var addr = ''
        if (row.media_province !== 0 && row.media_province !== null) {
          addr += this.cityDataList.province_list[row.media_province]
        }
        if (row.media_city !== 0 && row.media_city !== null) {
          addr += this.cityDataList.city_list[row.media_city]
        }
        if (row.media_county !== 0 && row.media_county !== null) {
          addr += this.cityDataList.county_list[row.media_county]
        }
        if (row.media_community_addr !== '') {
          addr += row.media_community_addr
        }
        this.baiduLocalSearch.center = addr
      }
    },
    MapClick (tp, target) {
      this.baiduLocalSearch.lng = tp.point.lng
      this.baiduLocalSearch.lat = tp.point.lat
      this.mediaForm.media_lng = tp.point.lng
      this.mediaForm.media_lat = tp.point.lat
    },
    pointSet () {
      if (this.baiduLocalSearch.lng !== 0 && this.baiduLocalSearch.lng !== undefined) {
        util.Post('media/editpoint', this.baiduLocalSearch).then(res => {
          if (res.rpStatus === 10000) {
            this.$message({ message: '设置点位坐标成功', type: 'success' })
            var arr = this.MediaDataList.filter(a => a.media_id === this.baiduLocalSearch.media_id)
            if (arr.length > 0) {
              arr[0].media_lat = this.baiduLocalSearch.lat
              arr[0].media_lng = this.baiduLocalSearch.lng
            }
          }
        })
        this.mapdialogVisible = false
      }
    },
    MapSearch(){
      if(this.searchKey){
        this.localSearchKey=this.searchKey
      }
    },


    // 导入
    handlePreview (file, fileList) {
      if (/\.(xls|xlsx)$/.test(file.name.toLowerCase())) {
        const filereader = new FileReader()
        filereader.onload = ev => {
          try {
            const data = ev.target.result
            const workbook = read(data, { type: 'binary' })
            var sheet = utils.sheet_to_json(workbook.Sheets[workbook.SheetNames[0]])

            if (sheet.length > 0) {
              var params = []
              sheet.forEach(a => {
                params.push(a)
              })
              util.Post('media/uploadfromexcel', params).then(res => {
                if (res.rpStatus === 10000) {
                  this.$message.success('导入成功')
                  this.GetDataList()
                } else {
                  this.$message.error(res.rpMsg)
                }
              })
              this.$refs.upload.clearFiles()
            }
          } catch (e) {
            this.$message.error('上传数据错误:' + e)
          }
        }
        filereader.readAsBinaryString(file.raw)
      }
      else{
        this.$message.error('上传格式不正确，请上传xls或者xlsx格式')
        return false
      }
    },

    // 导出
    async createExportData () {
      var form = {
        pageNo: 0,
        pageSize: 0
      }
      var result = await util.Get('media/getmediadataList', form)
      if (result.rpStatus === 10000) {
        if (result.list.length > 0) {
          result.list.forEach(a => {
            if (a.media_city > 0) {
              a.city = this.CheckProvice(a, 1)
            }
            if (a.media_county > 0) {
              a.county = this.CheckProvice(a, 2)
            }
            if (a.media_package_id > 0) {
              var arr = this.PackageDataList.filter(b => b.mp_id === a.media_package_id)
              if (arr.length > 0) { a.media_package = arr[0].mp_name } else { a.media_package = '' }
            }
          })
        }

        this.exportData = result.list
        return this.exportData
      }
    },
    finishDownload () {
      this.$message({
        message: '导出数据成功',
        type: 'success'
      })
    }
  },
  watch: {
    'mediaForm.media_province': function (newId) {
      this.cityList = { 0: '请选择' }
      this.countyList = { 0: '请选择' }
      var id = parseInt(newId)
      var end = id + 9900
      var start = id + 100
      for (var key in this.cityDataList.city_list) {
        var k = parseInt(key)
        if (k >= start && k <= end) {
          this.cityList[key] = this.cityDataList.city_list[key]
        }
      }
      if (this.ischange) {
        this.mediaForm.media_city = '0'
      }
    },
    'defaultCityForm.dc_province':function(newId){
      this.cityList = { 0: '请选择' }
      var id = parseInt(newId)
      var end = id + 9900
      var start = id + 100
      for (var key in this.cityDataList.city_list) {
        var k = parseInt(key)
        if (k >= start && k <= end) {
          this.cityList[key] = this.cityDataList.city_list[key]
        }
      }
      if(this.ischange){
        this.defaultCityForm.dc_city ='0' 
      }
        
    },
    'mediaForm.media_city': function (newId) {
      this.countyList = { 0: '请选择' }

      var id = parseInt(newId)
      var end = id + 99
      var start = id + 1

      for (var key in this.cityDataList.county_list) {
        var k = parseInt(key)
        if (k >= start && k <= end) {
          this.countyList[key] = this.cityDataList.county_list[key]
        }
      }
      if (this.ischange) {
        this.mediaForm.media_county = '0'
      }
      this.ischange = true
    }
  }
}
</script>

<style scoped>
.content{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}
.tool{
    display: flex;
    flex-direction: row;
    justify-items: flex-start;
    align-items: center;
}

.el-table{
    margin-top: 15px;
}
.el-link{
    margin-right: 11px;
    font-size: 12px;
}
::v-deep .el-dialog{
  --el-bg-color:#000741 !important;
}
::v-deep .el-dialog__header{
  --el-text-color-primary:#1effff;
  --el-text-color-regular:#fff;
  padding: 0px !important;
  background-color: #309CE2;
  height: 60px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

::v-deep .el-dialog__title{
    color: white;
}

::v-deep .el-dialog__headerbtn .el-dialog__close{
    color: white;
} 
</style>
